/* Pallette */
:root {
  --color-1: #f9fbfd;
  --color-2: #edf2fa;
  --color-3: #c2e7ff;
  --color-4: #bad3fc;
  --color-5: #2684fc;
  --color-6: #4f709c;
  --color-7: #5a7a7a;
  --color-8: #929292;
  --black: #181919;
}

*::selection {
  background-color: var(--color-4);
  color: var(--black);
}
.container {
  margin: 0 auto;
  width: 800px;
}

/* Font */
html {
  font-family: "Roboto", sans-serif;
}

/* Body */
body {
  margin: 0;
  background-color: var(--color-1);
}

/* Navigation */
.vertical-menu {
  background-color: var(--color-2);
  display: none;
}
.vertical-menu ul {
  margin: 0;
  padding: 10px 10px 10px;
  list-style: none;
  display: block;
  text-align: center;
}
.vertical-menu li {
  margin: 5px 0;
}

.vertical-menu li:hover {
  background-color: var(--color-3);
}

.vertical-menu-button {
  display: none;
  padding: 15px;
  border-radius: 10px;
}

.vertical-menu-button:hover {
  background-color: var(--color-3);
  color: var(--black);
  border-radius: 10px;
}

header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--color-1);
  filter: drop-shadow(0px 8px 5px var(--color-2));
}
header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  margin: 0 auto;
}
header ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
}
header a {
  color: var(--color-6);
  text-decoration: none;
  font-size: 16pt;
  padding-right: 20px;
}
header a:hover {
  color: var(--color-7);
}

.lang {
  font-size: 10pt;
  color: var(--color-7);
}

/* Main Content */
main {
  background-color: var(--color-1);
  height: auto;
  min-height: 100vh;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 150px;
}

.logo h1 {
  padding: 0;
}

/* Description */
.description {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: var(--color-2);
}

.description h1 {
  color: var(--color-6);
  font-size: 36pt;
  font-weight: 700;
  font-family: "Tilt Warp", sans-serif;
}

.description h3 {
  color: var(--color-7);
  font-size: 16pt;
  font-weight: 400;
  font-style: italic;
}

.google-marketplace-badge img {
  height: 68px;
}

/* Features */
.features {
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: var(--color-4);
  background-image: url(../assets/background.jpg);
  background-size: cover;
  background-position: center;
}

.features h1 {
  color: var(--color-6);
  font-size: 24pt;
  font-weight: 700;
}

.features h2 {
  color: var(--black);
  font-size: 16pt;
  font-weight: 700;
}

.right {
  text-align: right;
  display: block;
  margin-left: auto;
}

.example {
  border-radius: 10px;
  filter: drop-shadow(10px 10px 5px var(--color-7));
  width: 750px;
}

/* Guide */

.two-column {
  display: flex;
  div {
    margin: 10px;
  }
  img {
    border-radius: 10px;
    filter: drop-shadow(10px 10px 5px var(--color-7));
  }
}

/* Contact */
.contact {
  padding-top: 50px;
  padding-bottom: 25px;
  background-color: var(--color-2);
}

.contact h1 {
  color: var(--black);
  font-size: 24pt;
  font-weight: 700;
}

.contact h2 {
  color: var(--color-6);
  font-size: 16pt;
  font-weight: 700;
}

.contact a {
  color: var(--color-6);
  text-decoration: none;
}
.contact a:hover {
  color: var(--color-7);
}
.contact a:active {
  color: var(--color-6);
}

/* Terms */
.terms {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: var(--color-2);
}

.terms h1 {
  color: var(--color-6);
  font-size: 24pt;
  font-weight: 700;
}

.terms h2 {
  color: var(--black);
  font-size: 18pt;
  font-weight: 700;
}

/* Footer */
.footer {
  padding: 25px 0;
  text-align: center;
}
.footer p {
  line-height: 200%;
  color: var(--color-7);
}
.footer a {
  color: var(--black);
  text-decoration: none;
}
.footer a:hover {
  color: var(--color-7);
}
.footer a:active {
  color: var(--color-6);
}

.footer-tag {
  font-size: smaller;
}

.legal {
  font-size: small;
  text-align: left;
}

/* Donate Button */
.donate-button {
  padding: 10px 20px;
  text-decoration: none;
  width: 30%;
  border-radius: 50px;
  background-color: var(--color-4);
  color: var(--black);
  font-size: 14pt;
  font-weight: 700;
}

.donate-button:hover {
  background-color: var(--color-3);
}

/* Mobile Views */
/* Width adjustments for an OK mobile-view */
@media screen and (max-width: 900px) {
  .container {
    width: 90%;
  }

  header nav {
    width: 90%;
  }

  .features h1 {
    font-size: 20pt;
  }

  .features h2 {
    font-size: 12pt;
  }

  .example {
    width: 100%;
  }

  .two-column {
    img {
      width: 200px;
    }
  }

  .footer {
    font-size: small;
  }

  .footer-tag {
    font-size: x-small;
  }
}

@media screen and (max-width: 580px) {
  .vertical-menu-button {
    display: block;
  }
  header ul {
    display: none;
  }
  .two-column {
    display: block;
  }

  .example {
    width: 80%;
  }
}

@media screen and (max-width: 445px) {
  .logo img {
    height: 100px;
  }
  .google-marketplace-badge img {
    height: 45px;
  }
}

@media screen and (max-width: 320px) {
  .footer-tag {
    font-size: xx-small;
  }
  .logo img {
    display: none;
  }
}

/* Conversion Animation */
.convert-example {
  margin: 10px 0;
  padding: 10px 15px;
  border: 1px solid var(--color-8);
  background-color: var(--color-1);
}
.run-animation {
  -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
  animation: 1.5s highlight 1.5s 1 normal forwards;
  background-color: none;
  background: linear-gradient(
    90deg,
    var(--color-4) 50%,
    rgba(255, 255, 255, 0) 50%
  );
  background-size: 200% 100%;
  background-position: -100% 0;
}

@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}

mark {
  background: none;
}

/* Language Selector */
.lang-selector {
  select {
    background-color: white;
    border: 1px solid var(--color-8);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--color-6);
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    padding: 0 10px 0 16px;
    margin-right: 20px;
    outline: none;
  }
}
